import {
    loginURL, forgotPasswordURL, resetPasswordURL,
    getUserURL, changePasswordURL, getPermissionByIdURL,
    getOrganizationIdURL, getRoleUserURL, resendLoginEmail,
    // gpSessionURL, cardTokenizeURL, gpcheckout, registerSubscriptionURL, submitSubscriptionURL
} from '../constants/apiUrls';
import {
    postAxios, getAxios,
    // postgpAxios
} from '../services/Axios';
import { appReference } from '../constants/appReference';

// constantes
const initialState = {
    user: null,
    permission: null,
    token: null,
    expiration: null,
    isLogged: false,
    gotpermission:true,
    isEmailSent: false,
    resetPassword: false,
    alertMessage: null,
    loading: false,
    error: null,
    redirectToThankYou: false,
    checkoutError: "",
    response: null
};

// types
const USER_SUCCESS = 'USER_SUCCESS';
const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
const TOKEN_SUCCESS = 'TOKEN_SUCCESS';
const AUTH_ERROR = 'AUTH_ERROR';
const CHECKOUT_ERROR = 'CHECKOUT_ERROR';
const SUBS_ERROR = 'SUBS_ERROR';
const PASSWORD_EMAIL_SUCCESS = 'PASSWORD_EMAIL_SUCCESS';
const PASSWORD_SUCCESS = 'PASSWORD_SUCCESS';
const CLEAR_RESPONSE = 'CLEAR_RESPONSE';
const LOADING_OUT = 'LOADING_OUT';
const LOADING_AUTH = 'LOADING_AUTH';
const LOADING_SIGNUP = 'LOADING_SIGNUP';
const LOG_OUT = 'LOG_OUT';
const ALERT_MESSAGE = 'ALERT_MESSAGE';
const LENGUAGE_ID_SUCCESS = 'LENGUAGE_ID_SUCCESS';

// reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_OUT:
            return { ...state, loading: false };
        case LOADING_AUTH:
            return { ...state, loading: true, error: null };
        case LOADING_SIGNUP:
            return { ...state, loading: true, response: "subscription:executingTransaction", error: null };
        case TOKEN_SUCCESS:
            return {
                ...state, token: action.payload, expiration: action.expiration,
                error: null, isLogged: true, loading: false
            };
        case SIGNUP_SUCCESS:
            return {
                ...state, response: action.response, checkoutError: action.payload,
                error: null, isLogged: false, loading: false, redirectToThankYou: true
            };
        case USER_SUCCESS:
            return {
                ...state, user: action.payload, permission: action.permission,gotpermission:!!action.permission,
                resetPassword: false, error: null, loading: false
            };
        case PASSWORD_EMAIL_SUCCESS:
            return {
                ...state, isEmailSent: true, response: action.response,
                error: null, loading: false
            };
        case PASSWORD_SUCCESS:
            return {
                ...state, resetPassword: true, response: action.response,
                error: null, loading: false, alertMessage: null
            };
        case CHECKOUT_ERROR:
            return {
                ...state, error: action.payload, alertMessage: action.alert, response: action.response, loading: false
            };
            case SUBS_ERROR:
            return {
                ...state,  loading: false, checkoutError: action.payload, subscriptionAlertMessage: action.alert,
                response: action.payload
            };
        case AUTH_ERROR:
            return {
                ...state, error: action.payload, loading: false, alertMessage: action.alert,
                response: action.payload
            };
        case CLEAR_RESPONSE:
            return { ...state, response: null };
        case ALERT_MESSAGE:
            return { ...state, alertMessage: action.payload };
        case LOG_OUT:
            return { ...initialState };
        case LENGUAGE_ID_SUCCESS:
            return { ...state, lenguageId: action.payload };
        default:
            return state;
    }
};

// actions
export const loginAction = (user) => async (dispatch) => {
    dispatch({ type: LOADING_AUTH });
    try {
        const res = await postAxios(loginURL, {
            email: user.email,
            password: user.password,
            subdomain: window.location.protocol + "//" + window.location.hostname,
            //subdomain: "https://fos.fosonline.services",
            //subdomain: "https://store-test-account.fos-develop.softonitg.com/",
            //subdomain: "https://fosdemo.fosonline.services",
            appReference: appReference
        });
        if (res.status === 200) {
            const token = res.data.response.token;
            const expiration = res.data.response.expiration;
            localStorage.setItem('token', token);
            localStorage.setItem('expiration', expiration);
            return dispatch({
                type: TOKEN_SUCCESS,
                payload: token,
                expiration: expiration
            });
        }
        else {
            //console.log(res);
            throw new Error(res);
        }
    } catch (error) {
         
        var messageError = "auth:errorEmailOrPass";
        if (error.status === 403)
            messageError = "auth:errorExpiredPass";
        if (error.response.data.exception && error.response.data.exception.includes("expire")){
            messageError = error.response.data.message;
        }
        dispatch({
            type: AUTH_ERROR,
            payload: messageError
        });
    }
};

export const renewTokenAction = (token, expiration) => async (dispatch) => {

    token && localStorage.setItem('token', token);
    expiration && localStorage.setItem('expiration', expiration);
    return dispatch({
        type: TOKEN_SUCCESS,
        payload: token,
        expiration: expiration
    });
};

export const forgotPasswordSendEmailAction = (email) => async (dispatch) => {
    dispatch({ type: LOADING_AUTH });
    try {
        const res = await postAxios(forgotPasswordURL, {
            email: email,
            subdomain: window.location.protocol + "//" + window.location.hostname,
            appReference: appReference
        });
        if (res.status === 200) {
            return dispatch({
                type: PASSWORD_EMAIL_SUCCESS,
                response: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        dispatch({
            type: AUTH_ERROR,
            payload: error.response ? error.response.data.message : "auth:errorNonexistentEmail"
        });
    }
};
export const resendFirstLoginEmail = (user) => async (dispatch) => {
    dispatch({ type: LOADING_AUTH });
    try {
        const res = await postAxios(resendLoginEmail, {
            email: String(user.email),
            subdomain: window.location.protocol + "//" + window.location.hostname,
            appReference: appReference
        });
        if (res.status === 200) {
            return dispatch({
                type: PASSWORD_EMAIL_SUCCESS,
                response: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        dispatch({
            type: AUTH_ERROR,
            payload: error.response ? error.response.data.message : "auth:errorSendingEmail"
        });
    }
};
export const resendFirstLoginEmailMobile = (clientStaff) => async (dispatch) => {
    dispatch({ type: LOADING_AUTH });
    try {
        const res = await postAxios(resendLoginEmail, {
            email: String(clientStaff.email),
            appReference: 3
        });
        if (res.status === 200) {
            return dispatch({
                type: PASSWORD_EMAIL_SUCCESS,
                response: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        dispatch({
            type: AUTH_ERROR,
            payload: error.response ? error.response.data.message : "auth:errorSendingEmail"
        });
    }
};
export const resetPasswordAction = (user) => async (dispatch) => {
    dispatch({ type: LOADING_AUTH });
    try {
        const res = await postAxios(resetPasswordURL, {
            password: user.password,
            subdomain: window.location.protocol + "//" + window.location.hostname,
            confirmPassword: user.confirmPassword,
            token: user.token,
            email: user.email
        });
        if (res.status === 200) {
            return dispatch({
                type: PASSWORD_SUCCESS,
                response: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        ////console.log("error stat", error.response,error.response.data)
        /* if (error.response.data.statusCode >= 500)
         {
             dispatch({
                 type: AUTH_ERROR,
                 payload:  "auth:errorResettingPass"
             });
         }*/
        dispatch({
            type: AUTH_ERROR,
            payload: "auth:errorResettingPass"
        });
    }
};

export const changePasswordAction = (user) => async (dispatch, getState) => {
    dispatch({ type: LOADING_AUTH });
    try {
        const email = getState().auth.user.email;
        const res = await postAxios(changePasswordURL, {
            email: email,
            appReference: appReference,
            currentPassword: user.currentPassword,
            subdomain: window.location.protocol + "//" + window.location.hostname,
            newPassword: user.password
        });
        if (res.status === 200) {
            return dispatch({
                type: PASSWORD_SUCCESS,
                response: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());
        dispatch({
            type: AUTH_ERROR,
            payload: error.response ? error.response.data.message : "auth:errorChangingPassword"
        });
    }
};

export const alertMessage = () => dispatch => {
    dispatch({
        type: ALERT_MESSAGE,
        payload: "newPasswordAlert"
    });
};

export const getUser = () => async (dispatch, getState) => {
    try {
        const { isLogged } = getState().auth;
        const userRedux = getState().auth.user;
        if (isLogged) {
            dispatch({ type: LOADING_AUTH });
            const res = await getAxios(getUserURL);
            if (res.status === 200) {
                const user = res.data.response;
                const permissionRes = await getAxios(getPermissionByIdURL, { id: user.id });
                const organizationRes = await getAxios(getOrganizationIdURL, { id: user.organizationId });
                const adminRes = await getAxios(getRoleUserURL + user.id);
                if (permissionRes.status === 200 && organizationRes.status === 200) {
                    const permission = permissionRes.data.response;
                    const organization = organizationRes.data.response;
                    permission && localStorage.setItem('permission-fos', JSON.stringify(permission));
                    localStorage.setItem('user-fos', JSON.stringify(
                        {
                            firstLogin: userRedux ? userRedux.temporaryPasswordLogin : user.temporaryPasswordLogin,
                            isAdmin: adminRes.data.response.role.name === 'Admin',
                            ...user
                        }));
                    localStorage.setItem('lng-fos', organization.defaultLanguageId);
                    dispatch({
                        type: LENGUAGE_ID_SUCCESS,
                        payload: organization.defaultLanguageId
                    });
                    return dispatch({
                        type: USER_SUCCESS,
                        payload: {
                            firstLogin: userRedux ? userRedux.temporaryPasswordLogin : user.temporaryPasswordLogin,
                            isAdmin: adminRes.data.response.role.name === 'Admin',
                            ...user
                        },
                        permission: permission,
                        gotpermission : !!permission
                    });
                }
            }
            throw new Error();
        }
    } catch (error) {
        if (error.response && error.response.status === 401)
            dispatch(logoutAction());

        dispatch({
            type: AUTH_ERROR,
            //payload: error.response ? error.response.data.message : "auth:errorLoadingUser"
            payload: "auth:errorLoadingUser"
        });
    }
}

export const clearError = () => dispatch => {
    dispatch({
        type: AUTH_ERROR,
        payload: ''
    });
}

export const clearResponse = () => dispatch => {
    dispatch({ type: CLEAR_RESPONSE });
};

export const readActiveUserAction = () => (dispatch) => {
    if (localStorage.getItem('user-fos') && localStorage.getItem('token') &&
        localStorage.getItem('permission-fos') && localStorage.getItem('lng-fos')) {
        dispatch({
            type: USER_SUCCESS,
            payload: JSON.parse(localStorage.getItem('user-fos')),
            permission: JSON.parse(localStorage.getItem('permission-fos')),
           
        });
        dispatch({
            type: TOKEN_SUCCESS,
            payload: localStorage.getItem('token'),
            expiration: localStorage.getItem('expiration')
        });
        dispatch({
            type: LENGUAGE_ID_SUCCESS,
            payload: localStorage.getItem('lng-fos')
        });
        /* if (localStorage.getItem('region')) {
             dispatch({
                 type: REGION_SUCCESS,
                 payload: localStorage.getItem('region')
             });
         }*/
    }
};

export const logoutAction = () => (dispatch) => {
    dispatch({ type: LOG_OUT });
    localStorage.clear();
};
export const closeLoading = () => (dispatch) => {
    dispatch({ type: LOADING_OUT });
  
  
};

